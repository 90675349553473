<template>
    <v-navigation-drawer color="white" permanent clipped-left app>
        <v-app-bar flat>
            <v-card class="pa-4">
                <v-img src="@/assets/logo.png" contain justify="center">
                </v-img>
            </v-card>

        </v-app-bar>
        <v-divider></v-divider>
        <v-card height="75%" flat tile class="overflow-y-auto pa-0">
            <v-list dense v-if="isSuperAdmin || isAdmin || isClientAdmin">
                <v-list-item @click="navigate('/')" :disabled="itemListDisabled">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Home </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-group prepend-icon="mdi-account-circle" no-action :value="true" :disabled="itemListDisabled">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title> Manage </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="item in manage" :key="item.title" @click="navigate(item.link)"
                        :disabled="itemListDisabled">
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"> </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"> </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list-group>

                <v-divider></v-divider>
                <!-- <v-list-item @click="navigate('/kioskAnalytics')">
                    <v-list-item-icon>
                        <v-icon>mdi-presentation-play</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Kiosk Analytics </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider> -->
                <v-list-item @click="navigate('/configureClient')" v-if="assignedRole.data.permissions.config"
                    :disabled="itemListDisabled">
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Configure Client </v-list-item-title>
                </v-list-item>
                <!-- Kiosk Simulator option, to be removed post Beta Testing -->

                <!-- Kiosk Simulator option, to be removed post Beta Testing -->
                <v-divider></v-divider>
                <v-list v-if="$route.path === '/telepresence'">
                    <v-select v-model="selectedGroup" outlined label="Select Group" class="mx-2" dense
                        :items="getGroups" item-text="name" item-value="name"></v-select>
                    <v-list dense>
                        <v-list-item v-for="(user, i) in getUsers" :key="i">
                            <v-row class="pr-3" no-gutters>
                                <v-col cols="10" align-self="center" class="py-0">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon small left
                                                :color="getColor(user.data().onlineStatus)">mdi-checkbox-blank-circle</v-icon>
                                            {{ user.data().name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="2">
                                    <v-list-item-action>
                                        <v-icon color="primary" @click="
                                            initiateCall(
                                                user
                                            )
                                            " class="pl-1">
                                            mdi-video
                                        </v-icon>
                                    </v-list-item-action>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-list>
            </v-list>
            <v-list v-else dense>
                <v-list-item @click="navigate('/')" :disabled="itemListDisabled">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Home </v-list-item-title>
                </v-list-item>
                <!-- Kiosk Simulator option, to be removed post Beta Testing -->
                <v-divider></v-divider>
                <v-list-item @click="navigate('/kioskDemo')">
                    <v-list-item-icon>
                        <v-icon>mdi-mushroom</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> Kiosk Simulator </v-list-item-title>
                </v-list-item>
                <!-- Kiosk Simulator option, to be removed post Beta Testing -->
                <v-divider class="mb-2"></v-divider>
                <v-select v-model="selectedGroup" outlined label="Select Group" class="mx-2" dense :items="getGroups"
                    item-text="name" item-value="name" :disabled="disabled"></v-select>
                <v-card-text v-if="disabled">
                    The Select Group will be available after call accepted.
                </v-card-text>
                <v-list dense>
                    <v-list-item v-for="(user, i) in getUsers" :key="i">
                        <v-row class="pr-3" no-gutters>
                            <v-col cols="10" align-self="center" class="py-0">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon small left
                                            :color="getColor(user.data().onlineStatus)">mdi-checkbox-blank-circle</v-icon>
                                        {{ user.data().name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-col>
                            <v-col cols="2">
                                <v-list-item-action>
                                    <v-icon color="primary" @click="
                                        initiateCall(
                                            user
                                        )
                                        " class="pl-1">
                                        mdi-video
                                    </v-icon>
                                </v-list-item-action>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
import orgGroupList from '../orgGroupList.vue';
import router from '../../../router';
export default {
    name: 'drawer',
    computed: {
        ...mapGetters(['assignedRole', 'appData', 'isSuperAdmin', 'isAdmin', 'isClientAdmin', 'userProfile', 'userGroups', 'allRegisteredUsers', 'getCallId']),
        getGroups() {
            console.log('User Groups: ', this.userGroups)
            return this.userGroups
        },
        getUsers() {
            console.log('Check this: ', this.userGroups, this.selectedGroup)
            if (this.selectedGroup === '') {
                return []
            } else {
                let associatedGrp = this.userGroups.filter(grp => grp.name === this.selectedGroup)
                return this.allRegisteredUsers.filter(user => user.data().groups.includes(associatedGrp[0].id));
            }

        }
    },
    components: {
        orgGroupList
    },
    watch: {
        $route(to, from) {
            if (to.path === '/telepresence') {
                this.disabled = false
                this.itemListDisabled = true
            } else {
                this.selectedGroup = ''
                this.disabled = true
                this.itemListDisabled = false
            }
        }
    },
    data() {
        return {
            selectedItem: 1,
            selectedGroup: '',
            isHomePage: true,
            disabled: true,
            itemListDisabled: false,
            manage: [
                {
                    title: "Users",
                    icon: "mdi-account-multiple-outline",
                    link: "/userManagement",
                },
                {
                    title: "Organization Groups",
                    icon: "mdi-account-group-outline",
                    link: "/groupManagement",
                },
                {
                    title: "Roles",
                    icon: "mdi-card-account-details-outline",
                    link: "/roleManagement",
                },
                {
                    title: "Kiosks",
                    icon: "mdi-presentation-play",
                    link: "/kioskManagement",
                },
            ],
            analytics: [
                {
                    title: "Kiosk",
                    icon: "mdi-presentation-play",
                    link: "/kioskAnalytics",
                }
            ],
        }
    },
    methods: {
        navigate(link) {
            if (router.currentRoute.path === "/liveCall") {
                window.open(link, "_blank");
            } else {
                router.push(link);
            }
        },
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
        initiateCall(user) {
            console.log('User: ', user.data())
            if (user.data().onlineStatus === 'Unavailable' || user.data().onlineStatus === 'Busy') {
                this.$store.commit('setCallStatusDialog', {
                    show: true,
                    message: user.data().name + ' is currently ' + user.data().onlineStatus + '. Please try again after sometime.'
                })
            } else {
                this.$store.dispatch('addToExistingCall', {
                    callId: this.getCallId,
                    userId: user.id,
                    userName: user.data().name
                })
            }
        }
    },
    mounted() {
        if (typeof window !== 'undefined') {
            // Use window.location.pathname to determine the current path
            this.isHomePage = router.currentRoute.path === '/kioskUpdates';
        }
    }
}
</script>
<style>
.v-toolbar__content {
    padding: 0px !important;
}
</style>